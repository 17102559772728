@import "../theme-colors";

/*  ICON
------------------------------------------------- */
.ic-location {
  width: 24px !important;
  height: 24px !important;
}
.ic-eye,
.ic-dots,
.ic-reset-password,
.ic-slack,
.ic-microsoft-entra-id,
.ic-notification,
.ic-category,
.ic-person,
.ic-calendar,
.ic-addItem,
.ic-lock,
.ic-sort,
.ic-plus,
.ic-menu,
.ic-setCover,
.ic-badge,
.ic-icon {
  width: 24px !important;
  height: 24px !important;
}
.ic-globe {
  width: 20px !important;
  height: 20px !important;
}
.ic-paint {
  width: 25px;
  height: 25px;
}
.ic-search,
.ic-arrow {
  width: 20px;
  height: 20px;
}
.ic-address {
  width: 18px;
  height: 18px;
}
.ic-info {
  color: $secondary-text-color;
  display: inline-block;
  margin-left: 8px;
  width: 12px;
  height: 12px;

  &:hover {
    cursor: pointer;
  }
}
.ic-singleSortIcon,
.ic-sortIcon,
.ic-arrowBold,
.ic-checkmark,
.ic-startHoliday,
.ic-hourglass {
  width: 16px;
  height: 16px;
}

.ic-gift,
.ic-star,
.ic-events {
  width: 16px;
  height: 20px;
}

.ic-coins {
  width: 23px;
  height: 23px;
}

.ic-analyticsArrow {
  width: 12px;
  height: 8px;
}

.ic-warning-mark {
  width: 14px;
  height: 14px;
}
