@import "../../../theme-colors";

.merchStore {
  .pageTitleBlock {
    margin-bottom: 50px;
  }
  &__categoryTitle {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    gap: 32px;

    &:after {
      flex: 1 1;
      content: "";
      padding: 0.5px;
      background-color: $text-links-color;
    }
  }

  &__categoryGoods {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 80px;
  }
}

.merchItemPopup {
  max-width: 650px;
  width: 100%;

  &__title {
    margin: 0;
  }

  &__completeBtnBlock {
    justify-content: flex-end;
  }

  &__completeBtn {
    max-width: fit-content !important;
    margin-top: 20px;
    &:nth-child(1) {
      margin-right: 20px;
    }
  }
  .popup-panel-form {
    padding-bottom: 80px !important;
  }
  .merchDeliveryBlock,
  .label {
    margin: 20px 8px 20px 0;
  }
}

.categoryText {
  &__infoWrap {
    width: 50%;
    margin-bottom: 20px;
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;

    p + p {
      margin-top: 0;
    }
  }

  &__infoTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $secondary-text-color;
  }

  &__infoDesc {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: $text-links-color;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    margin-top: 0;
  }

  &__aboutTitle {
    font-size: 22px;
    line-height: 31px;
    margin: 20px 0 16px;
  }

  &__desc {
    font-size: 14px;
    line-height: 21px;
  }
}

.merchItemPopup .merchItemPopup__wrap .merchItemPopup__submitBtn {
  max-width: max-content;
}

@media screen and (max-width: 1600px) {
  .merchStore {
    &__categoryGoods {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
  }
}

@media screen and (max-width: 1000px) {
  .merchStoreTooltip {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .merchStore {
    &__box {
      box-shadow: none;
    }
  }
  .categoryText {
    &__title {
      font-size: 24px;
      line-height: 32px;
    }

    &__infoWrap {
      width: 50%;
    }
  }
}

@media screen and (max-width: 450px) {
  .merchStore {
    &__categoryGoods {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  .categoryText {
    &__infoWrap {
      width: 100%;
    }
  }

  .merchItemPopup {
    .popup-footer .group-btn {
      gap: 20px;
    }

    .merchItemPopup__wrap .popup-footer .group-btn .merchItemPopup__closeBtn,
    .merchItemPopup__wrap .merchItemPopup__submitBtn {
      max-width: 100%;
      margin-left: 0;
    }
    .merchItemPopup__completeBtnBlock {
      justify-content: flex-start;
    }
  }
}
