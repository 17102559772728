.alert-message {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 16px 0 32px;
  width: 100%;

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__title,
  &__desc {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
  }

  &__desc {
    font-weight: 400;
    color: $main-text-color;
  }

  svg {
    min-width: 14px;
    margin-top: 1.5px;
  }
}

.alert-message.warning {
  .alert-message__title {
    color: $red-color;
  }
}
