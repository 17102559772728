@import "../../theme-colors";

/*   news page
------------------------------------------------- */
.content {
  padding-top: 8px;
  display: grid;
  grid-template-columns: 1fr 535px;
  column-gap: 127px;
}

.essentials {
  &__header {
    margin-bottom: 32px;

    .pageTabs {
      margin: 0;
    }
  }
}

.essentialCard {
  display: block;
  padding: 0;
  margin-bottom: 16px;

  &__img {
    width: 100%;
    height: 144px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;

    &--merch {
      height: 100%;
      max-height: 300px;
      object-fit: contain;
      padding-top: 24px;
    }
  }

  &__info {
    padding: 24px;

    .achievementCard__coins {
      min-width: max-content;
    }
  }

  &__block {
    gap: 10px;
  }

  &__name {
    margin-bottom: 16px;
    font-weight: 400;
  }

  &__labelsWrap {
    flex-wrap: wrap;
    gap: 10px;

    .ic-coins {
      margin-right: 10px;
    }
  }
}

.news .wrap {
  margin-top: 32px;
}

.news-head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    margin: 0;
  }
}
.news-item {
  display: grid;
  grid-template-columns: 260px 1fr;
  column-gap: 64px;

  .badgeMessageMenuWrap {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    position: relative;

    .popoverBtn {
      position: absolute;
      top: -10px;
      right: 0;
    }
  }

  .user {
    display: flex;

    .user-avatar {
      width: 40px;
      height: 40px;
      margin: 0 16px 0 0;
    }

    .companyLogo img {
      object-fit: contain;
    }

    .userInfo {
      display: flex;
      flex-direction: column;
      .user-name {
        margin-bottom: 2px;
      }
      .user-name a,
      span {
        font-size: 14px;
      }
    }
    .activeStatusIndicator {
      &:after {
        width: 16px;
        height: 16px;
      }
    }
  }
  .news-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    border-radius: 10px;
    background-color: $main-bg-color;
    border: 1px solid $border-secondary-color;
    width: 100%;
    max-width: 259px;
    height: 259px;
  }
  .news-image,
  .news-image-with-coins {
    height: auto;
    max-height: 200px;
  }
  .date-text {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-text-color;
  }
  .title {
    margin: 32px 0 16px;
  }
  .message {
    margin-top: 16px;
    white-space: pre-wrap;

    .ql-editor {
      padding: 0 !important;
    }
  }
  .sender-text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;

    &--bold {
      font-weight: 700;
    }

    .achievementParticipantsCounter {
      margin: 0;

      .user-name {
        font-size: 14px;
        line-height: 20px;
      }
      .user-avatar {
        width: 24px;
        min-width: 24px;
        height: 24px;
        .avatarSign {
          font-size: 10px;
          line-height: 16px;
        }
      }

      .changeCountUsers {
        left: -14px;
      }
    }
  }

  .coins {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: $text-links-color;
    margin-top: 16px;
    text-align: center;
  }
  .article {
    width: 100%;
    overflow: hidden;
    padding-top: 16px;
  }
}
.news-item + .news-item {
  margin-top: 16px;
}
.badgeMessageReactions {
  margin-top: 24px;

  .emojiBlock {
    &__emoji {
      padding: 4px 8px;
      gap: 8px;
      border-radius: 50px;
      border: 1px solid $border-secondary-color;
    }
  }
}

.badgeMessagePopover {
  transform: translate(-18px, 5px);
}

@media screen and (max-width: 1800px) {
  .content {
    grid-template-columns: 1fr 450px;
    column-gap: 60px;
  }
}

@media screen and (max-width: 1600px) {
  .content {
    grid-template-columns: 1fr 400px;
    column-gap: 32px;
  }
  .news-item {
    grid-template-columns: 200px 1fr;
    column-gap: 40px;
  }
}

@media screen and (max-width: 1500px) {
  .content {
    grid-template-columns: 1fr 380px;
    column-gap: 24px;
  }
}

@media screen and (max-width: 1366px) {
  .content {
    display: flex;
    flex-direction: column-reverse;
    gap: 48px;
    .newsCalendar__content {
      max-width: 100%;
    }
  }
  .news-sideContent {
    .end-message {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .news-item {
    display: flex;
    column-gap: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .news-item {
    .article {
      padding-top: 32px;
    }
    .title {
      margin: 16px 0;
      font-size: 20px;
      line-height: 30px;
    }
    .badgeMessageMenuWrap {
      .popoverBtn {
        position: static;
      }
    }
  }
}
