@import "../../../theme-colors";

.userTimeOffBalances {
  margin-bottom: 32px;

  &__menu {
    margin-bottom: 32px;
  }
  .timeOffBalanceItem {
    padding: 12px 12px 24px 24px;
    flex-basis: 250px;
    flex-shrink: 0;

    &__header {
      align-items: flex-start;
      margin-bottom: 8px;

      .actionBtn-regular {
        border: none;
        position: relative;
        top: -10px;
        right: -10px;
      }
    }

    &__policyInfo {
      justify-content: flex-start;
    }

    &__icon {
      margin-right: 16px;
      width: 20px;
      height: 20px;
    }

    &__policyName {
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
    }

    &__balance {
      margin-bottom: 16px;
    }

    &__balanceCount,
    &__balanceLabel {
      letter-spacing: -0.02em;
      color: $main-text-color;
    }

    &__balanceCount,
    &__infinityIcon {
      display: inline-block;
      margin-right: 8px;
    }

    &__balanceCount {
      font-size: 48px;
      line-height: 64px;
    }

    &__infinityIcon {
      width: 64px;
      height: 64px;
    }

    &__balanceLabel.infinityBalance {
      position: relative;
      bottom: 10px;
    }

    &__usedUnits {
      font-size: 12px;
      line-height: 18px;
    }

    &__usedAnnualAllowance {
      display: inline-block;
      margin-right: 8px;
      font-weight: 700;
      color: $green-color;
    }
  }
}

.popup.editTimeOffBalance {
  max-width: 600px;
  width: 100%;

  .policyTypeNameWrap {
    justify-content: flex-start;
    gap: 12px;
  }

  .policyTypeIcon {
    width: 20px;
    height: 20px;
  }

  .balanceLabel {
    margin-top: 32px;
  }

  .balanceInputWrap {
    gap: 16px;

    .customInputNumber {
      flex-grow: 1;
    }

    .unitLabel {
      font-weight: 700;
    }
  }
}

.timeOffsBacklog {
  margin-top: 32px;
  .main-title {
    margin-bottom: 32px;
  }

  .table-wrap .table-inner {
    min-width: 1200px;
    word-wrap: break-word;

    .item-th {
      display: flex;
      align-items: center;
    }

    .item-col:nth-child(1) {
      width: 100%;
      min-width: 25%;
      justify-content: flex-start;
    }

    .item-col:nth-child(2) {
      width: 100%;
      min-width: 25%;
    }
    .item-col:nth-child(3) {
      width: 100%;
      min-width: 20%;
    }
    .item-col:nth-child(4) {
      width: 100%;
      min-width: 15%;
    }
    .item-col:nth-child(5) {
      width: 100%;
      min-width: 15%;
      text-align: right;
      display: flex;
      justify-content: flex-end;

      .actionBtn-tertiary {
        flex-shrink: 0;
      }
    }
  }
}

.timeOffRequestPopup {
  max-width: 600px;
  width: 100%;

  .wrap {
    padding: 0 16px 0 5px;
  }

  .rs-input-group-sm.rs-input-group > .rs-input,
  .rs-input-group-sm.rs-input-group > .rs-input-group-addon {
    height: 40px;
  }

  .form-style .label + .label {
    margin-top: 32px;
  }

  .customTextEditor .ql-editor {
    min-height: 80px;
    padding: 12px 12px 12px 15px;
  }

  .customTextEditor .ql-toolbar.ql-snow {
    padding: 8px;
  }

  .main-title {
    margin: 0;
  }

  .requestDaysBlock {
    margin: 16px 0 32px;

    &__text {
      font-size: 12px;
      line-height: 16px;
      color: $text-links-color;

      &--bold {
        font-weight: bold;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      max-height: 264px;
      height: 100%;
      gap: 24px;
      margin: 0;
      overflow-y: auto;

      scrollbar-width: thin;
      scrollbar-color: $border-secondary-color #fff;

      &::-webkit-scrollbar {
        width: 10px !important;
      }
      &::-webkit-scrollbar-track {
        background: $blocks-bg-color;
      }
      &::-webkit-scrollbar-thumb {
        width: 10px;
        background: $border-secondary-color;
        border-radius: 10px;
      }
    }

    &__item {
      display: grid;
      grid-template-columns: 15% 15% 55% 15%;
    }

    &__dayweek {
      color: $secondary-text-color;
      width: 80px;
    }

    &__remainingDays {
      font-weight: 700;
    }

    .tagLabel {
      cursor: initial;
      height: 22px;
    }

    &__text-negative {
      color: $red-color;
      .requestDaysBlock__text {
        color: $red-color;
      }
    }
  }

  &__divider {
    margin: 32px 0 24px;
    width: 100%;
    height: 2px;
    background-color: $border-secondary-color;

    &----padding {
      margin: 24px 16px;
    }
  }

  .structureCard {
    flex-direction: row;
    justify-content: flex-start;
    gap: 40px;

    &__name,
    &__position {
      text-align: left;
    }
  }

  .structureCard__avatarPlaceholder {
    margin: 0;
    width: 120px;
    height: 120px;
  }

  .structureCard__userInfo {
    flex-grow: initial;
  }

  &__date-block {
    color: $secondary-text-color;
    margin: 32px 0;
  }

  .structureCard__menuBtn {
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  .customTextEditor .ql-editor {
    min-height: 120px !important;
  }
}

@media screen and (max-width: 600px) {
  .timeOffRequestPopup {
    .requestDaysBlock {
      &__item {
        grid-template-columns: 1fr 50px 1fr 1fr;
        column-gap: 10px;
      }
    }
  }
}
