.peopleListContentPopup {
  &__tabsWrap {
    gap: 16px;
    padding: 0 24px 16px 12px;

    .pageTabs {
      margin-bottom: 0;
    }
  }
}

.peopleListContent {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
  row-gap: 16px;

  .user-avatar {
    margin-right: 0;
  }

  .user-card.box {
    margin-bottom: 0;
  }

  &__popover {
    padding: 16px 24px;
    transform: translate(-18px, 5px);
  }

  &__menuPopover.customPopover {
    z-index: 1060;
  }
}

@media screen and (max-width: 1400px) {
  .peopleListContentPopup {
    margin: 60px auto 0;

    &__tabsWrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .peopleListContent {
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 800px) {
  .peopleListContentPopup {
    .rs-modal-body {
      max-height: 300px !important;
    }
  }
  .peopleListConten__popover {
    padding: 16px;
  }
}

@media screen and (max-width: 400px) {
  .peopleListContentPopup {
    .rs-modal-body {
      padding: 0 10px 12px;
    }
  }
}
